html {
  overflow-x: hidden;
  max-width: 100vw;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.raw {
  font-family: monospace;
  white-space: pre;
  overflow: scroll;
  background: #111;
  padding: 5px;
  margin-top: 5px;
}

body,
#root {
  background-color: #222;
  display: flex;
  justify-content: center;
  color: white;
  width: 100%;
}

.item {
  background-color: black;
  display: flex;
  flex-direction: column;
}

.item>div {
  display: flex;
  width: 100%;
    box-sizing: border-box;
    --overflow: scroll;
}

.item .img {
  width: 180px;
  min-height: 124px;
  --background-color: sienna;
  flex-shrink: 0;
}
.item .details {

  gap: 3px;
  padding: 5px;

  overflow: hidden;
  /* max-width: 100vw; */
  flex-grow: 0;
  /* flex-shrink: 0; */
  display: flex;
  flex-direction: column;
}
.content {
  max-width: 1200px;
  gap: 10px;
  display: flex;
  padding: 10px 0px;
  width: 100%;
  flex-direction: column;
}

.item .title {
  font-size: large;
  font-weight: bold;
}
.item .subject {
  font-style: italic;
  color: #ccc;
  font-size: small;
}
.item .description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

button {
  font-family: monospace;
  border: 1px solid sienna;
  border-radius: 5px;
  color: sienna;
  background-color: transparent;
  cursor: pointer;

  padding: 1px 6px;
}

a {
  color: sienna;
  text-decoration: underline dotted;
}

.controls {
  display: flex;
  justify-content: end;
  gap: 5px;
}

.controls > div {
  background-color: black;
  color: white;
  font-weight: bold;
  padding: 2px 8px;
  display: flex;
  align-items: center;
}

.title span {
  color: #555;
}
.title span::before {
  content: ' ';
}


@media only screen and (max-width: 600px) {
  button {
   font-size: 20px;
  }
}

button.showRaw {
margin-right: 5px;
  }

  button.fltr.active ,
button.use.active {
background-color: lightgreen;
    color: darkgreen;
    font-weight: bold;
    border-color: darkgreen;
}
body {
  padding-bottom: 40px;
}
